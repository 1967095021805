import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import Header from '../components/Header/Header'
import Layout from '../components/Layout/Layout'
import Post from '../components/Home/UpdatesSection/Post/Post'

const Blog = ({ data }) => {
  let posts = data.allMarkdownRemark.edges.filter(post => post.node.rawMarkdownBody.length > 0)

  return (
    <Layout title={data.markdownRemark.frontmatter.heading}>
      <Helmet>
        <script type="text/javascript">{`
          document.querySelector('[data-tool="verify"]').classList.add('hidden')
          document.querySelector('[data-tool="register"]').classList.add('hidden')
          document.querySelector('[data-tool="locate"]').classList.add('hidden')
        `}</script>
      </Helmet>
      <Header background={data.markdownRemark.frontmatter.headerImage.photo} alt={data.markdownRemark.frontmatter.headerImage.altText}>{data.markdownRemark.frontmatter.heading}</Header>
      <div className="main">
        {posts.length > 0 ? posts.map(post => (
          <Post title={post.node.frontmatter.title} excerpt={post.node.excerpt} photo={post.node.frontmatter.photo.photo ? post.node.frontmatter.photo.photo : null} link={`/blog${post.node.fields.slug}`} date={post.node.frontmatter.publishDate} alt={post.node.frontmatter.photo.altText} />
        )) : <h2>{data.markdownRemark.frontmatter.noPosts}</h2>}
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`query BlogQuery {
  markdownRemark(frontmatter: {title: {eq: "Blog"}}){
    frontmatter {
      heading
      headerImage {
        photo {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        altText
      }
      noPosts
    }
  }
  allMarkdownRemark(
    sort: { fields: [frontmatter___publishDate], order: DESC }
    filter: { frontmatter: { newsArticle: {eq: false}}}
  ) {
    edges {
      node {
        id
        rawMarkdownBody
        timeToRead
        excerpt(pruneLength: 280)
        fields {
          slug
        }
        frontmatter {
          title
          heading
          publishDate
          photo {
            photo {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
            altText
          }
        }
      }
    }
  }
}
`

export default Blog