import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import moment from 'moment'

import * as styles from './Post.module.css'

const post = (props) => {
  const image = getImage(props.photo)

  return (
    <>
      <div className={styles.update_preview}>
        <div className={styles.update_image}>
          {props.photo && <GatsbyImage image={image} alt={props.altText} style={{ width: '100%' }} />}
        </div>
        <div className={styles.update_excerpt}>
          <h3>{props.title}</h3>
          <small><strong>{props.author}</strong></small><br />
          <small>{moment(props.date).format('MMMM DD, YYYY')}</small>
          <p>{props.excerpt}</p>
          <div className="flex-row btn-row">
            <Link to={`${props.link}`} className="default-btn">Continue Reading Update <span className="visually-hidden">{props.title}</span></Link>
          </div>
        </div>
      </div>

    </>
  )
}

export default post